import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import clsx from "clsx"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Button = ({ className, thin = false, children, ...params }) => {
  return (
    <Link
      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
      className={clsx(
        "px-6 text-lg text-white font-bold rounded-lg",
        thin ? "py-2" : "py-4",
        className
      )}
      {...params}
    >
      {children}
    </Link>
  )
}

const Splash = ({ data, bg }) => {
  const fill = "w-full h-full"
  const pin_tl = "top-0 left-0 absolute"
  const cover = clsx(fill, pin_tl)

  return (
    // Stack Container
    <div className="relative text-gray-800" style={{ height: "80vh" }}>
      {/* Responsive background image */}
      <Img className={cover} fluid={data.coverImage.childImageSharp.fluid} />
      {/* Radial gradient to provide a subtle shadow */}
      <div
        className={cover}
        style={{
          background:
            "radial-gradient(707.31px at 71.81% 56.67%, rgba(255, 255, 255, 0) 0%, rgba(5, 0, 0, 0.12) 100%)"
        }}
      />

      {/* White background for the left panel */}
      <div
        className={clsx(
          cover,
          "h-full max-w-xl px-16",
          "flex flex-col justify-center"
        )}
        style={{ background: "#ffffffbd" }}
      >
        {/* Actual text, logo, and buttons */}
        <h1
          style={{ fontSize: "5rem", minWidth: "30rem" }}
          className="leading-tight font-serif mb-8"
        >
          We're all they've got.
        </h1>
        <p
          className="text-xl mb-8"
          style={{ wordWrap: "break-word", hyphens: "auto" }}
        >
          ICC Phillipines is a small local team on a mission to provide a family
          for 15 children, no matter what it takes. Our parent organization
          recently went bankrupt and finances are tight. We need your help.
        </p>
        {/* Buttons row */}
        <div className="flex w-full justify-start">
          <Button className="mr-8 bg-indigo-500" to="/status">
            What's going on?
          </Button>
          <Button className="mr-8 bg-pink-500" to="/support">
            Support Us
          </Button>
        </div>
      </div>
    </div>
  )
}

const BarTitle = ({ lineClass = "bg-pink-300", className, children }) => {
  return (
    <div className={className}>
      <h2 className={"font-serif text-6xl text-gray-800 z-50 relative"}>
        {children}
      </h2>
      <div
        className={lineClass}
        style={{
          height: "10px",
          position: "relative",
          top: "-28px",
          left: "-8px",
          width: "114%",
          "max-width": "90vw"
        }}
      />
    </div>
  )
}

const IllustratedSection = ({
  title = "Untitled Section",
  flip = false,
  titleLineClass = "bg-pink-400",
  fluidImgData,
  ctaText = "Donate Now",
  ctaClass = "bg-pink-400",
  ctaLinkTo = "/support",
  className = "bg-blue-100",
  children
}) => {
  let prose = (
    <div className={clsx("w-1/2", flip ? "pl-8" : "pr-8")}>
      <div className="prose">{children}</div>
      <div className="flex justify-center mt-8">
        <Button thin className={ctaClass} to={ctaLinkTo}>
          {ctaText}
        </Button>
      </div>
    </div>
  )

  let img = (
    <div className="w-1/2">
      <Img fluid={fluidImgData} />
    </div>
  )

  let a = prose
  let b = img
  if (flip) {
    a = img
    b = prose
  }

  return (
    <section
      className={clsx(
        "flex flex-col items-center pt-4 pb-16 text-gray-900",
        className
      )}
    >
      <BarTitle lineClass={titleLineClass} className={clsx("mb-8")}>
        {title}
      </BarTitle>
      <div className="flex w-full max-w-6xl" style={{ height: "25rem" }}>
        {a}
        {b}
      </div>
    </section>
  )
}

const OrphanageSection = ({ data }) => {
  return (
    <IllustratedSection
      title="Orphanage"
      ctaText="Donate Now"
      ctaClass="bg-indigo-500"
      ctaLinkTo="/support?what=orphanage"
      titleLineClass="bg-indigo-400"
      className="bg-gray-200"
      fluidImgData={data.orphanageImage.childImageSharp.fluid}
    >
      <h3>A Place to Call Home</h3>
      <p>Located in a certain place... 15 acres of greenery</p>
      <p>
        Founded in xxxx by Joe Public. Spent 15 years establishing orphanages
        around the world, etc.
      </p>
      <p>Run by 5 local staff. (link to staff section of about us page)</p>
      <h3>Operating Budget</h3>
      <p>
        We have enough money to operate for the next 2 months. Seeking
        additional sponsors.
      </p>
      <h3>Roof renovation project</h3>
      <p className="mb-2">50% funded.</p>
    </IllustratedSection>
  )
}

const SchoolSection = ({ data }) => {
  return (
    <IllustratedSection
      flip
      title="School"
      titleLineClass="bg-indigo-400"
      ctaText="Support Education"
      ctaClass="bg-indigo-500"
      ctaLinkTo="/support?what=school"
      className="bg-white"
      fluidImgData={data.teacherImage.childImageSharp.fluid}
    >
      <h3>Learning: The Gift that Keeps Giving</h3>
      <p>Located near the orphanage. Serves 30 community children.</p>
      <p>
        Founded to serve the orphanage kids, 5 years ago we decided to open it
        up to community children as well.
      </p>
      <p>
        Run by 5 local staff, as well as many foreign volunteers. You should
        consider coming and helping.
      </p>
      <p>
        When a child learns to read and write, they are empowered to go out and
        change their situation.
      </p>
      <h3>Operating Budget</h3>
      <p>
        We have enough money to operate for the next 4 months. Seeking
        additional sponsors.
      </p>
    </IllustratedSection>
  )
}

const BottomSplash = ({ data }) => {
  return (
    <div className="bg-indigo-500 py-48 flex text-white">
      <form className="m-auto flex flex-col items-start w-full max-w-3xl -mt-4">
        <h2 className="text-5xl font-bold">Follow our story</h2>
        <p className="mb-6 max-w-2xl text-lg">
          We have a dynamic blog telling about what we do. It's amazing. This is
          our last chance to call you to action. Some more text goes here. Blah
          blah bla.
        </p>
        <div className="flex w-full items-center">
          <input
            className="rounded text-2xl text-gray-800 py-2 px-4 w-128 flex items-center flex-grow"
            placeholder="you@example.com"
            type="text"
            name="email"
          />
          <Button thin className="ml-4 bg-pink-500">
            Subscribe
          </Button>
        </div>
      </form>
    </div>
  )
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <div className="">
        <Splash data={data} />

        <OrphanageSection data={data} />
        <SchoolSection data={data} />

        <BottomSplash data={data} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    coverImage: file(relativePath: { eq: "kids-raising-hands.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    orphanageImage: file(relativePath: { eq: "school_building.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teacherImage: file(relativePath: { eq: "teacher_teaching.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
